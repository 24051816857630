// Import Vanilla Framework
@import "node_modules/vanilla-framework/scss/vanilla";

// Vanilla overrides
$increase-font-size-on-larger-screens: false;
$color-link-visited: $color-link;
$application-layout--side-nav-width-collapsed: 3rem;

// Import settings
@import "settings";

// Import utils
@import "utils";

// Import functions
@import "functions/z-index";

// Import custom patterns
@import "custom/status_icons";
@import "custom/tables";

// Settings
$grid-max-width: 100%;
$color-navigation-background: $color-brand;

@include vanilla;
@include vf-p-icon-settings;

// Include additional icons
@include vf-p-icon-applications;
@include vf-p-icon-controllers;
@include vf-p-icon-fullscreen;
@include vf-p-icon-models;
@include vf-p-icon-machines;
@include vf-p-icon-pin;
@include vf-p-icon-units;
@include vf-p-icon-user;

// Fixes focus styles until fix implemented in Vanilla
// https://github.com/canonical-web-and-design/vanilla-framework/issues/3352
:focus-visible {
  outline: 0.1875rem solid $color-focus;
}

:focus:not(:focus-visible) {
  outline: 0;
}
