@import "vanilla-framework/scss/vanilla";

.banner {
  background: $color-caution;
  color: $color-brand;
  max-height: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  transition: all 0.25s;

  &[data-active="true"] {
    max-height: 100%;
    padding: 0.5rem;

    @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
      padding-left: 48px;
    }
  }

  &[data-variant="caution"] {
    background: $color-caution;
  }

  &[data-variant="positive"] {
    background: $color-positive;
    color: $color-light;

    a {
      color: $color-light;
      font-weight: 400;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    margin: 0;
    max-width: 100%;
    padding: 0 1.5rem 0 0;
  }

  &__close {
    &,
    &:hover {
      background: none;
      border: 0;
    }

    height: 1rem;
    position: absolute;
    right: 0.5rem;
    top: 0.15rem;
    width: 1rem;
  }
}
