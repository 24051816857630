@import "vanilla-framework/scss/vanilla";
@import "vanilla-framework/scss/base_placeholders";
@import "vanilla-framework/scss/patterns_icons";

@include vf-p-icons;
@include vf-b-placeholders;

.share-model {
  .title-wrapper {
    display: flex;

    .p-button--base.has-icon {
      display: flex;
      margin-bottom: 0;
    }

    .p-icon--chevron-up {
      display: block;
      padding-right: 1rem;
      transform: rotate(90deg);
    }

    .p-icon--share {
      @include vf-icon-share($color-dark);

      margin-right: 0.5rem;
    }

    &__heading {
      display: flex;
      margin-bottom: 0;
    }
  }

  .add-user-btn {
    display: flex;
    margin-bottom: 0;
    padding-left: 0.5rem;

    @media (min-width: $breakpoint-small) {
      display: none;
    }
  }

  .p-panel__content {
    padding: 1rem;
  }

  .p-notification--negative {
    margin: 0 1rem;
  }

  .p-radio {
    margin-bottom: 1rem;

    .help-text {
      color: $color-mid-dark;
      display: block;
      font-size: 0.875rem;
      padding-left: 2rem;
    }
  }

  .action-wrapper {
    display: flex;
    margin-top: 2rem;

    button {
      margin-left: auto;
    }
  }

  .share-cards {
    &__heading {
      display: flex;

      button {
        margin-bottom: 0.5rem;
        margin-left: auto;
        padding-left: 1rem;
      }
    }
  }

  // Toggle the cards and form view on small screens
  @media (max-width: $breakpoint-small) {
    [data-mobile-show-add-user="false"] {
      .add-new-user {
        display: none;
      }
    }

    [data-mobile-show-add-user="true"] {
      .share-cards {
        display: none;
      }
    }
  }
}
