@import "vanilla-framework/scss/vanilla";

.p-strip.is-shallow.controllers-overview {
  padding-top: 0;
}

.controllers-overview {
  width: 100%;

  &__chart {
    margin-right: 2rem;
  }

  &__container {
    display: flex;
  }
}
