@import "vanilla-framework/scss/vanilla";
@import "../../scss/functions/z-index";

.p-confirmation-modal {
  .p-modal {
    align-items: flex-end;
    // Vanilla override can be removed once this PR lands.
    // XXX https://github.com/canonical-web-and-design/vanilla-framework/pull/3673
    background: transparentize($color-dark, 0.5);
    justify-content: flex-end;
    z-index: z("infinity");

    @media (max-width: $breakpoint-x-small) {
      padding: 0;
    }

    &__dialog {
      flex-grow: 100;
      left: auto;
      max-width: 522px;
      padding: 1rem 1.5rem;
      // position: relative is required because vanilla's modal dialog switches
      // position types from relative to absolute under $breakpoint medium.
      position: relative;
      right: auto;
      top: auto;
    }
  }

  h5 {
    margin-bottom: 0;
    padding-top: 0 !important;
  }

  pre {
    // reset the vanilla pre styling
    background-color: inherit;
    border: none;
    padding: 0;
    // extra styles
    white-space: pre-wrap;
    word-break: keep-all;
  }

  &__sub-header {
    color: $color-mid-dark;
    font-size: 0.875rem;
  }

  &__info-group {
    margin-bottom: $spv-outer--medium;

    &:last-child {
      margin-bottom: 1.5rem;
    }
  }
}
