@import "vanilla-framework/scss/vanilla";
@import "../../scss/functions/z-index";

.slide-panel {
  @include vf-animation(transform, brisk, out);
  $top-header-height: 64px;

  background-color: $color-x-light;
  height: calc(100% - #{$top-header-height});
  max-width: 100vw;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: $top-header-height;
  transform: translateX(100%);
  width: 100%;
  z-index: z("beta");

  @media (min-width: $breakpoint-medium) {
    box-shadow: $box-shadow;
    width: 60vw;
  }

  &[aria-hidden="false"] {
    transform: translateX(0);
  }

  [data-loading="true"] {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .p-modal__close {
    margin: 1.5rem 1rem 1rem;

    @media (max-width: $breakpoint-medium - 1) {
      margin-top: 4rem;
    }
  }

  &__content {
    padding: 1.5rem 1rem 1rem;

    @media (max-width: $breakpoint-medium - 1) {
      padding-top: 4rem;
    }
  }

  &__tables {
    overflow-x: scroll;
    scrollbar-width: none;

    table {
      margin-bottom: 1rem;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
}
