// Capitalise every world of a string
.u-capitalise {
  text-transform: capitalize;

  // Capitalise only the first letter of a string
  &--first-letter {
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

// Overflow overrides
.u-overflow {
  &--scroll {
    overflow: scroll;
  }

  &--visible {
    overflow: visible;
  }
}

// Display overrides
.u-flex {
  display: flex;

  &--block {
    flex: 1 auto;
  }
}

// Extend has-icon to work outside of buttons
// https://github.com/canonical-web-and-design/vanilla-framework/issues/3102
.has-icon [class*="p-icon"]:first-child {
  margin-right: 0.25rem;
  margin-top: 0.25rem;
}
