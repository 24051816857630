@import "vanilla-framework/scss/vanilla";

.controllers {
  th {
    padding-bottom: 6px;
    padding-top: 6px;
  }

  td,
  th {
    &:first-child {
      min-width: 25%;
      white-space: nowrap;
    }
  }

  &--header {
    border-bottom: 1px solid $colors--light-theme--border-default;
    color: $color-mid-dark;
    display: inline-grid;
    font-size: 0.76562rem;
    font-weight: 400;
    grid-template-columns: repeat(2, 50%);
    margin-bottom: 1rem;
    padding: 0.25rem 0 0.5rem 1rem;
    text-transform: uppercase;
    width: 100%;

    .controllers__heading {
      color: $color-dark;
      padding-top: 0.8rem;
      position: relative;
      top: 0.5rem;
    }

    .controllers--register {
      text-align: right;
    }

    button {
      margin: 0;
      max-width: 15rem;
    }
  }

  .is-disconnected {
    color: $color-negative;
  }

  .controllers--registered-tooltip {
    @include vf-icon-help($color-mid-light);

    margin-left: 0.4rem;
  }
}
