@import "vanilla-framework/scss/vanilla";

.models {
  .chip-group {
    margin: 1.5rem 0 1rem;

    @media (min-width: $breakpoint-medium) {
      margin: 1rem 0;
    }
  }

  &__header {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr;
    opacity: 1;
    padding: 0.5rem 0.15rem;
    transition: opacity 0.25s;

    &[data-disabled="true"] {
      opacity: 0.5;
      pointer-events: none;
    }

    @media (max-width: $breakpoint-large) {
      .models__count {
        display: none;
      }
    }

    @media (min-width: $breakpoint-medium) and (max-width: $breakpoint-large) {
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: $breakpoint-large) {
      grid-template-columns: 120px 1fr 280px;
    }

    .search-and-filter {
      @media (min-width: $breakpoint-medium) {
        max-width: 270px;
        position: absolute;
        right: 0.75rem;
        top: 0.7rem;
        width: 100%;
      }

      .p-chip {
        // Override required until upstream fix
        // https://github.com/canonical-web-and-design/vanilla-framework/issues/3416
        &[aria-pressed="true"] {
          background-color: rgba($color-x-dark, 0.15);
        }
      }
    }
  }

  // Tooltips override
  .p-tooltip--top-center .p-tooltip__message {
    left: calc(50% - 0.875rem);
    margin-bottom: -0.5rem;
  }
}
