@import "vanilla-framework/scss/vanilla";

.toast-card {
  background-color: $color-x-light;
  border: 1px solid $color-mid-light;
  border-radius: 5px;
  box-shadow: $box-shadow;
  color: $color-dark;
  max-width: 350px;
  padding: 1rem 0.75rem;
  width: 100%;

  &__body {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1rem 1fr 1rem;
  }

  &__message {
    margin-bottom: 2rem;
  }

  i {
    display: inline-block;
    padding-top: 1.5rem;
  }

  .p-icon--close {
    cursor: pointer;
    padding-top: 1.5rem;
  }

  &[data-type="positive"] {
    border-left: 3px solid $color-positive;
  }

  &[data-type="caution"] {
    border-left: 3px solid $color-caution;
  }

  &[data-type="negative"] {
    border-left: 3px solid $color-negative;
  }

  &__undo {
    display: flex;
    width: 100%;

    [class~="p-button"] {
      color: $color-link;
      margin-bottom: 0;
      margin-left: auto;
      width: auto;
    }
  }
}
