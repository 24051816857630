@import "../../scss/settings";
@import "vanilla-framework/scss/vanilla";
@import "../../scss/functions/z-index";

.info-panel {
  @media (min-width: $breakpoint-small) {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: $breakpoint-medium) {
    display: block;
  }

  .p-modal {
    position: fixed;
    z-index: z("zelda");

    &__dialog {
      width: 90%;
    }
  }

  &__pictogram {
    // Keep height and width in sync with the dimensions of
    // the Topology component.

    background: $color-light;
    border: solid 1px $color-mid-x-light;
    flex: 1;
    margin-bottom: 1rem;
    position: relative;

    .p-icon--fullscreen {
      bottom: 10px;
      cursor: pointer;
      position: absolute;
      right: 10px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr;

    // This needs to be kept in sync with the 1580px breakpoint in
    // InfoPanel.js:infoPanelDimensions.

    @media (min-width: 1580px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .p-muted-heading {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }
}
