@import "vanilla-framework/scss/vanilla";

.actions-panel {
  $magic-height: 200px;
  $magic-min-height: calc(100vh - #{$magic-height});

  .p-panel__header {
    padding: 0 1.5rem;
  }

  &__unit-list {
    margin-bottom: 1.125rem;
    padding: 0 1.5rem;
  }

  &__action-list {
    min-height: $magic-min-height;
    padding: 0 1.5rem;
  }

  &__drawer {
    background-color: white;
    border-top: 1px solid $color-mid-x-light;
    bottom: 0;
    height: 4.75rem;
    padding: 1rem 1.5rem 0 1.5rem;
    position: sticky;
    text-align: right;
  }
}
