@import "../../scss/settings";
@import "../../scss/functions/z-index";
@import "vanilla-framework/scss/vanilla";

.p-primary-nav {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  width: 100%;

  a,
  a:hover {
    text-decoration: none;
  }

  &__divider {
    background: $color-x-light;
    box-sizing: border-box;
    margin: 0 1rem 1rem;
    max-width: calc(100% - 2rem);
    opacity: 0;
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr 40px;
    padding: 0.75rem 0.5rem 0.35rem;
    position: relative;

    @media (min-width: $breakpoint-medium) {
      height: 4rem;
      padding: 0 0.5rem;
    }
  }

  &__toggle {
    align-items: center;
    background: none;
    border: 0;
    display: grid;
    margin: 0;
    padding: 0;

    .p-icon--contextual-menu {
      margin: auto;
    }

    &:hover,
    &:active {
      background: none !important;
    }

    &:focus {
      outline: 1px dotted $color-navigation-highlight;
    }
  }

  .p-list {
    margin-bottom: 1rem;

    [class^="p-icon"] {
      margin-right: 1rem;
      margin-top: 0.25rem;
    }
  }

  .p-list__item {
    padding: 0;

    .p-list__link {
      border-left: 3px solid transparent;
      color: $color-light;
      display: flex;
      padding: 0.25rem 0;
      padding-left: calc(1rem - 3px);
      width: 100%;

      &:hover {
        background-color: $color-navigation-highlight;
      }

      &.is-selected {
        background-color: $color-navigation-highlight;
        border-left-color: $color-navigation-accent;
      }
    }

    .p-list__icon {
      height: 16px;
      padding-right: 1.25rem;
      position: relative;
      top: 0.275rem;
    }
  }

  .p-primary-nav__bottom {
    margin-top: 0;

    @media (min-width: $breakpoint-medium) {
      opacity: 0;
    }

    .p-list {
      &__item {
        color: $color-x-light;
        display: flex;
        font-size: 0.875rem;

        .p-label--new {
          height: 1.5rem;
          margin-left: auto;
          margin-right: 1rem;
        }

        .version {
          color: $color-light;
          font-size: 0.875rem;
          padding-left: 1rem;
        }
      }
    }
  }

  .entity-count {
    background-color: $color-negative;
    border-radius: 50%;
    display: inline-block;
    font-size: 0.875rem;
    height: 1.5rem;
    margin-left: auto;
    margin-right: 1rem;
    padding: 0 0.25rem;
    text-align: center;
    width: 1.5rem;
  }

  .logo__text {
    @media (min-width: $breakpoint-medium) {
      opacity: 0;
    }
  }

  .p-primary-nav__bottom,
  .logo__text {
    @include vf-animation(opacity, brisk, ease-in-out);

    @media (min-width: $breakpoint-large) {
      opacity: 1;
    }
  }

  .p-primary-nav__divider {
    @include vf-animation(opacity, brisk, ease-in-out);

    @media (min-width: $breakpoint-large) {
      opacity: 0.1;
    }
  }

  &:hover {
    .p-primary-nav__bottom,
    .logo__text {
      opacity: 1;
    }
  }
}
