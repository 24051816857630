@import "vanilla-framework/scss/vanilla";

.p-button-group {
  align-content: center;
  column-gap: 1rem;
  display: flex;
  padding: 0.25rem 0;

  &__label {
    margin-right: 0.5rem;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__buttons {
    border: 1px solid $color-mid-dark;
    border-radius: 0.25rem;
    display: flex;
    margin-bottom: 0.5rem;
    overflow: hidden;
    width: 100%;

    @media (min-width: $breakpoint-medium) {
      margin-bottom: 0;
      width: auto;
    }
  }

  &__inner {
    align-items: center;
    display: flex;
    width: 100%;
  }

  &__button {
    border: none;
    border-radius: 0;
    border-right: 1px solid $color-mid-dark;
    color: $color-mid-dark;
    display: inline-block;
    flex: 1;
    height: 100%;
    line-height: 1.5;
    margin: 0 !important; // Required to override Vanilla button:not(:last-of-type):not(:only-of-type)
    padding: 0.3rem 1.65rem;
    text-align: center;
    text-transform: capitalize;

    &.is-selected {
      background: $color-mid-x-light;
      color: $color-dark;
    }

    &:last-child {
      border: 0;
    }
  }
}
