@import "vanilla-framework/scss/vanilla";

.l-aside {
  min-height: 100vh;
  overflow-y: scroll !important;
  padding: 0;
  position: relative;

  .p-panel {
    min-height: 100vh;
    position: relative;
  }

  .loading {
    align-items: center;
    background-color: $color-x-light;
    display: flex;
    height: 100vh;
    justify-content: center;
    width: 100%;
  }

  &.is-split {
    @media (min-width: $breakpoint-medium) {
      max-width: 768px;
      width: 66%;
    }

    .aside-split-wrapper {
      padding-bottom: 4rem;

      @media (min-width: $breakpoint-small) {
        display: flex;
        padding-bottom: 1rem;
      }

      .aside-split-col {
        width: 100%;

        @media (min-width: $breakpoint-small) {
          width: calc(50% - 1rem);

          &:first-child {
            border-right: 1px solid #ccc;
            margin-right: 2rem;
            padding-right: 2rem;
          }
        }
      }
    }
  }
}
