@import "vanilla-framework/scss/vanilla";

.p-chart {
  .is-disabled {
    fill: rgba($color-mid-light, 0.33);
  }

  .is-blocked {
    fill: $color-negative;
  }

  .is-alert {
    fill: $color-caution;
  }

  .is-running {
    fill: $color-mid-light;
  }

  @media (min-width: $breakpoint-large) {
    align-items: center;
    display: flex;

    &__legend {
      flex: 1;
    }
  }

  @media (max-width: $breakpoint-medium) {
    &__chart {
      display: none;
    }
  }
}

.p-list.p-legend {
  font-size: 0.9rem;
  margin: 0 1rem;

  .p-legend__item {
    padding: 0 1rem;
    position: relative;

    &::before {
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 0.5rem;
      left: 0;
      position: absolute;
      top: 0.5rem;
      width: 0.5rem;
    }

    &.label {
      font-size: 1rem;
      margin-bottom: 0.5rem;
      padding-left: 0;
    }

    &.is-blocked::before {
      background-color: $color-negative;
    }

    &.is-alert::before {
      background-color: $color-caution;
    }

    &.is-running::before {
      background-color: $color-mid-light;
    }
  }
}
