@import "../../scss/settings";
@import "vanilla-framework/scss/vanilla";
@import "../../scss/functions/z-index";

.skip-main {
  height: 1px;
  left: -9999px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;

  &:focus,
  &:active {
    background-color: $color-navigation-dark;
    border: 4px solid $color-caution;
    border-radius: 5px;
    color: $color-x-light;
    font-size: 1rem;
    height: auto;
    left: 50%;
    margin: 1rem;
    margin-left: -15%;
    outline: none;
    overflow: auto;
    padding: 1rem;
    text-align: center;
    top: auto;
    width: 30%;
    z-index: z("infinity");
  }
}

.l-main {
  background-color: $color-x-light;

  @media (min-width: $breakpoint-medium) {
    padding-left: 3rem;
  }

  @media (min-width: $breakpoint-large) {
    padding-left: 0;
  }

  > .p-notification--information {
    bottom: 1rem;
    margin: 0 1rem;
    position: absolute;
  }
}

.l-navigation {
  @include vf-animation(transform, brisk, ease-in-out);

  background: $color-sidebar;
  max-height: 100vh;
  max-width: 15rem;
  transform: translateX(0);

  &-bar {
    background: $color-sidebar;
    display: flex;
    height: 3rem;
    padding: 0.5rem;
    z-index: z("zelda");

    @media (min-width: $breakpoint-medium) {
      display: none;
    }

    .logo {
      position: relative;
      top: 3px;
    }

    .toggle-menu {
      margin-left: auto;
      max-width: 150px;
    }
  }

  @media (max-width: $breakpoint-medium - 1) {
    &[data-collapsed="true"] {
      transform: translateX(-100%);
    }
  }

  // Override Vanilla to always collapse navigation sidebar
  @media (min-width: $breakpoint-large - 1) {
    &[data-sidenav-initially-collapsed="true"] {
      & ~ .l-main {
        padding-left: 3rem;
      }

      height: 100vh;
      left: 0;
      max-width: 3rem;
      position: fixed;
      top: 0;

      .logo__text,
      .p-primary-nav__bottom,
      .p-primary-nav__divider {
        opacity: 0;
      }

      transition: all 0.25s;

      &:hover {
        max-width: 15rem;

        .logo__text,
        .p-primary-nav__bottom {
          opacity: 1;
        }

        .p-primary-nav__divider {
          opacity: 0.1;
        }
      }
    }
  }

  // Close user menu when side nav collapses so it remains active but appears
  // visually inactive until the user hovers the side nav again
  &[data-sidenav-initially-collapsed="true"] {
    .user-menu.is-active {
      max-height: $user-menu-height--inactive;
    }

    &:hover {
      .user-menu.is-active {
        max-height: $user-menu-height--active;
      }
    }
  }
}

.l-content {
  margin: 0.5rem 1rem;
}

.l-application {
  overflow-x: hidden;
}
