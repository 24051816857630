@import "../../scss/functions/z-index";

.login {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  height: 100vh;
  left: 0;
  place-content: center;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: z("infinity");

  &__inner {
    width: 280px;

    .p-button--positive,
    .p-button--neutral {
      margin: 0 auto;
      width: 100%;
    }

    .p-icon--spinner {
      top: -2px;
    }

    .error-message {
      margin-top: 0.5rem;

      .p-icon--error {
        margin-right: 0.5rem;
      }
    }
  }

  &__logo {
    display: block;
    margin: 0 auto 1rem;
    width: 110px;
  }
}
