.status-icon {
  display: inline-block;
  padding-left: 1.5rem;
  position: relative;

  &::before {
    content: "\00B7";
    font-size: 5rem;
    left: 0;
    position: absolute;
    top: -6px;
  }

  &.is-spinner::before {
    content: none;
  }

  &.is-spinner {
    padding-left: 0;

    .p-icon--spinner {
      margin-right: 0.5rem;
    }
  }

  &.is-failed,
  &.is-provisioning,
  &.is-down,
  &.is-error,
  &.is-blocked {
    &::before {
      color: $color-negative;
    }
  }

  &.is-maintenance,
  &.is-attention,
  &.is-stopped,
  &.is-pending,
  &.is-alert,
  &.is-waiting {
    &::before {
      color: $color-caution;
    }
  }

  &.is-unknown {
    &::before {
      color: $color-mid-light;
    }
  }

  &.is-completed,
  &.is-running,
  &.is-joined,
  &.is-active {
    &::before {
      color: $color-positive;
    }
  }
}
