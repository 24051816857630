// Custom breakpoints
$breakpoint-m-large: 1580px;

// Custom colors
$color-sidebar: #003b4e;
$color-navigation-dark: #002835;
$color-navigation-highlight: #004e68;
$color-navigation-accent: #3fc8f2;
$color-grey60: #999;
$color-blue-highlight: #ddedfe;

// User menu active/inactive heights
$user-menu-height--inactive: 2.5rem;
$user-menu-height--active: 7.5rem;
