@import "vanilla-framework/scss/vanilla";
@import "../../scss/settings";

.slide-panel.config-panel .slide-panel__content {
  padding: 1.5rem 0 0;
}

.config-panel {
  $magic-height: 200px;
  $magic-min-height: calc(100vh - #{$magic-height});

  &__modal-button-row-hint {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    text-align: left;
  }

  .config-content {
    @media (max-width: $breakpoint-large) {
      grid-template-columns: 1fr !important;
    }

    &.row {
      padding: 0;
    }
  }

  h5 {
    margin-bottom: 0.2rem;
  }

  pre {
    // reset the vanilla pre styling
    background-color: inherit;
    border: none;
    padding: 0;
    // extra styles
    white-space: pre-wrap;
    word-break: keep-all;
  }

  .full-size {
    display: flex;
    justify-content: center;
    min-height: $magic-min-height;
    text-align: center;
  }

  .config-panel__hide-button {
    @include vf-animation(opacity, brisk, out);

    opacity: 0;
  }

  .config-panel__show-button {
    opacity: 1;
  }

  &__reset-all {
    margin-left: auto;
  }

  &__message {
    padding-top: 6rem;

    @media (min-width: $breakpoint-medium) {
      padding-top: 3rem;
    }

    h4 {
      margin-top: 3rem;
    }
  }

  &--center-img {
    margin: 0 auto;
  }

  &__list-header {
    align-items: baseline;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0 1rem;
    padding: 0 1.5rem;

    @media (max-width: $breakpoint-x-small) {
      flex-direction: column;
    }

    @media screen and (max-width: $breakpoint-medium) {
      margin-top: 4rem;
      padding-right: 4rem; // Spacing for the close X
      padding-top: 1rem;
    }

    button {
      margin-bottom: 0;
      margin-left: 1rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      white-space: nowrap;

      @media screen and (max-width: $breakpoint-x-small) {
        margin-left: inherit;
      }
    }

    .entity-name {
      font-size: 1.3rem;
      margin-top: 0.3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__config-list {
    border-right: 1px solid $color-mid-light;
    padding-top: 1rem;

    @media screen and (max-width: $breakpoint-large) {
      border-right: none;
    }
  }

  &__list {
    min-height: $magic-min-height;
    padding: 0 1rem;
  }

  &__description {
    margin-top: 2rem;
    padding-right: 1.5rem;

    @media screen and (max-width: $breakpoint-large) {
      display: none !important;
    }

    &-wrapper {
      position: sticky;
      top: 1rem;
    }
  }

  &__no-description {
    min-height: $magic-min-height;
    padding: 0 5rem;
    position: sticky;
    text-align: center;
    top: 0;
  }

  &__drawer {
    background-color: white;
    bottom: 0;
    box-shadow: none;
    position: sticky;

    @media screen and (max-width: $breakpoint-x-small) {
      padding-right: 0;
    }

    &.is-open {
      box-shadow: $box-shadow;
    }
  }

  &__button-row {
    border-top: 1px solid $color-mid-light;
    margin: 0 1.5rem;
    text-align: right;

    button {
      margin-top: 1.2rem;
    }

    button.config-panel__save-button {
      @include vf-animation(opacity, brisk, out);

      span {
        padding-left: 1rem;
      }
    }
  }
}

.config-input {
  border-radius: 2px;
  cursor: pointer;
  padding: 0 0.5rem;

  &--focused {
    @include vf-animation(background-color, brisk, out);

    background-color: #ddedfe;
  }

  &--changed {
    background-color: $color-light;
  }

  &.config-input--focused.config-input--changed {
    background-color: $color-blue-highlight;
  }

  .p-icon--plus.config-input--view-description,
  .p-icon--minus.config-input--view-description {
    display: none;
    margin-right: 0.5rem;
    width: 0.875rem;

    @media screen and (max-width: $breakpoint-large) {
      display: inline-block;
    }
  }

  &--description {
    @include vf-animation(max-height, brisk, out);

    clear: both;
    display: none;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
    margin-left: 1.3rem;
    max-height: 0;
    overflow: hidden;

    @media screen and (max-width: $breakpoint-large) {
      display: block;
    }
  }

  button {
    color: $color-link;
    margin: 0.3rem 0 0;
    padding: 0;

    &:hover {
      background-color: inherit;
    }
  }

  @media screen and (max-width: $breakpoint-x-small) {
    button {
      width: auto;
    }
  }

  @media screen and (min-width: $breakpoint-large) {
    textarea {
      margin-bottom: 1.2rem !important;
    }
  }

  textarea {
    border: 1px solid $color-grey60;
    border-radius: 2px;
    height: 2.3rem;
    margin-bottom: 0.7rem;
    min-height: 2.3rem;
    resize: vertical;
  }
}
