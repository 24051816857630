@import "../../scss/settings";
@import "vanilla-framework/scss/vanilla";
@import "../../scss/functions/z-index";

.header {
  background-color: $color-x-light;
  border-bottom: 1px solid $color-mid-light;
  display: block;
  min-height: 4rem;
  padding: 0 1rem;
  z-index: z("gamma");

  @media (min-width: $breakpoint-medium) {
    align-items: center;
    display: grid;
    padding: 0 1.5rem;
    position: sticky;
    top: 0;
  }
}
